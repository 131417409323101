
import * as React from "react"

const Toggle = ({toggleHideCompleted}) => {


    return (
        <div className="flex justify-end py-3 md:px-9">
            <span class="ml-3 px-3 text-sm font-medium text-black-900 dark:text-black-300">Hide completed</span>
            <label for="default-toggle" class="inline-flex relative items-center cursor-pointer">
            <input type="checkbox" value="" id="default-toggle" class="sr-only peer" onChange={toggleHideCompleted}></input>
            <div class="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
            
            </label>
        </div>

    )
}

export default Toggle