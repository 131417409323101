const config = {};

config.subcategoryColor = {
    '01-Basement Base Price' : "bg-fuchsia-200",
    '02-Basement add-ons - General' : "bg-violet-400",
    "03-Basement add-ons - HVAC" : "bg-sky-400",
    "04-Basement add-ons - Electrical" : "bg-cyan-400",
    "05-Basement add-ons - Home Media" : "bg-emerald-300",
    "06-Basement add-ons - Doors and Stairs" : "bg-lime-400",
    "08-Basement add-ons - Other" : "bg-lime-400",
    "07-Basement add-ons - Flooring" : "bg-yellow-400",
    "10-Bathroom Items" : "bg-amber-300",
    "11-Bathroom Upgrades" : "bg-orange-400",
    "13-Kitchenette/Bar" : "bg-pink-300",
    "14-Bar Appliances" : "bg-orange-200",
    "12-Laundry" : "bg-fuchsia-400"

}

export default config;