import * as React from "react"
import axios from 'axios';
import Table from "../components/itemsTable";
import Toggle from "../components/toggle";


const groupItems = (items) => {
  let result = {};
  for (let item of items) {
    item.fields.id = item.id;
    if (result[item.fields['Subcategory']]) {
      result[item.fields['Subcategory']].push(item.fields)
    } else {
      result[item.fields['Subcategory']] = [item.fields]
    }
  }
  return result
}


const IndexPage = () => {
  const [items, setItems] = React.useState({})
  const [project, setProject] = React.useState("")
  const [message, setMessage] = React.useState("Loading ...");
  const [hideCompleted, sethideCompleted] = React.useState(false)

  let projectId = '';
  let params = {project : ""}
  if (typeof window !== "undefined") {
    params = Object.fromEntries((new URLSearchParams(window.location.search)).entries());
  }
  projectId = params.project
  const toggleHideCompleted = () => {
    sethideCompleted(!hideCompleted)
  }

  React.useEffect(() => {
    axios.get("https://api.airtable.com/v0/appqEWgVWPu6wcMpc/Client%20Checklist",
      {
        params: {
          "filterByFormula": `projectId = '${projectId}'`,
          "view" : "viw42ghLGyjMPj0fw"
        },
        headers: {
          "Authorization": `Bearer patUICwnkk9q8SnFv.da5a8cd0d87db095995b1c1b3a14458bf37af437b713777457c78ea771dfd11b`
        }
      }
    ).then(response => {
      let grouped = groupItems(response.data.records);
      setItems(grouped)
      if (response.data.records.length === 0) {
        setMessage("Your checklist could not be found")
      }
      setProject(response.data.records[0].fields['Project Name Text'])
    }).catch(err => {
      console.log(err);
      setMessage("Something went wrong ... Please reload the page, and if the issue persists, let your project manager know.")
    })
  }, [projectId])

  return (
    <main className="w-full h-full">
      <title>Checklist</title>

      {Object.keys(items) != 0 ?
        <div className="w-full h-full min-h-screen bg-gray-100 max-w-full">
          <h1 className="text-lg text-center font-medium py-3">{project}</h1>
          <Toggle toggleHideCompleted={toggleHideCompleted}></Toggle>
          <Table hideCompleted={hideCompleted} items={items}></Table>
        </div>
        :
        <h1 className="text-lg text-center font-medium py-3">{message}</h1>
      }
    </main>
  )
}

export default IndexPage
