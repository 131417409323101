
import * as React from "react"
import Row from './row';
import config from '../helpers/config';

const Subcategory = ({name, items, hideCompleted, setNote}) => {


    if (name == '02-Basement add-ons - General') {
        console.log(items.every(item => item['Completed']))
    }
    if(hideCompleted && items.every(item => item['Completed'])){
        return <></>
    }

    return (
        <>
        <tr key={name + "_subcategory"}>
            <td colSpan="9" className="px-6 py-4 whitespace-nowrap bg-slate-50">
                <div className="flex items-center">
                    <div className="ml-4">
                       
                        <span className={"px-2 inline-flex text-md leading-5 font-semibold rounded-full text-slate-900 " + (config.subcategoryColor[name] || "bg-violet-400")} >
                            {" " + name}
                        </span>
                    </div>
                </div>
            </td>
        </tr>
        {items.map((item) =>
            <Row hideCompleted={hideCompleted} item={item} setNote={setNote}></Row>
        )}
        </>

    )
}

export default Subcategory