import { data } from 'autoprefixer';
import axios from 'axios';
import * as React from 'react';
import ColorfulCircle from "./colorfulCircle"

const Row = ({ hideCompleted, item, setNote }) => {
    const [completed, setCompleted] = React.useState(item['Completed'] ? true : false);

    const markAsComplete = () => {
        setCompleted(true);
        axios.post("https://hook.integromat.com/31dle67gu77dofjar7orzm3ja4drmduo", data = { recordId: item.id, status: 'client-complete' })
    }

    if (hideCompleted && completed) {
        return <></>
    }
    return (
        <tr key={item.id}>
            <td className="px-6 py-2 whitespace-nowrap w-1/6">
                <div className="flex items-center">
                    <div className="ml-4">
                        <div className="text-sm font-medium whitespace-normal text-gray-900">{item['Item Name']}</div>
                    </div>
                </div>
            </td>
            <td className="px-6 py-2 whitespace-nowrap">
                {
                    completed ? "" :
                        <button className="px-2 inline-flex text-xs leading-5 font-semibold rounded-md bg-green-500 text-slate-50" onClick={markAsComplete}>Complete</button>
                }
            </td>

            <td className="px-6 py-2 whitespace-nowrap">
                {
                    item['MUST READ'][0].includes("http") ?
                        <a target="_blank" rel="noreferrer" href={item['MUST READ'][0]} className="px-2 inline-flex text-xs leading-5 font-semibold rounded-md bg-blue-400 text-slate-50"> Click to read</a>
                        : ""
                }
            </td>
            <td className="px-6 py-2 whitespace-nowrap">
                <div className="text-sm text-gray-900">{item['Due Date']}</div>
            </td>
            <td className="px-6 py-2 whitespace-nowrap">
                <ColorfulCircle circleColors={{ 'CONTRACTOR': " text-black-800 bg-green-200 ", 'CUSTOMER': " text-slate-50 bg-red-400 ", 'N/A': " text-black-800 bg-blue-200 ", "BY THE VENDOR" : " text-black-800 bg-green-200 " }[item['Delivered By']]} text={item['Delivered By']}></ColorfulCircle>
            </td>
            <td className="px-6 py-2 whitespace-nowrap">
                <ColorfulCircle circleColors={{ 'NOT REQUIRED': " text-black-800 bg-slate-200 ", 'REQUIRED': " text-slate-50 bg-red-400 " }[item['PM Review']]} text={item['PM Review']}></ColorfulCircle>
            </td>
            <td className="px-6 py-2 truncate">
                <div className="text-sm text-gray-900" >
                    {
                        item['Important Notes'] ?
                            <button data-notes={item['Important Notes']} onClick={(e) => { setNote(e.target.dataset.notes) }} className="px-2 inline-flex text-xs leading-5 font-semibold rounded-md bg-blue-400 text-slate-50">Click to read</button>
                            : ""
                    }


                </div>
            </td>
            <td className="px-6 py-2 whitespace-nowrap">
                <ColorfulCircle circleColors={completed ? " text-green-800 bg-green-200 " : " text-red-800 bg-red-200 "} text={completed ? "Done" : "Not Done"}></ColorfulCircle>
            </td>
            <td className="px-6 py-2 whitespace-nowrap">
                <ColorfulCircle circleColors={{ 'Self-selection': " text-black-600 bg-sky-200 ", 'Showroom visit': " text-black-600 bg-cyan-200 " }[item['Category']]} text={item['Category']}></ColorfulCircle>
            </td>
        </tr>
    )
}

export default Row