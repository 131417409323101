import * as React from "react"

const ColorfulCircle = ({circleColors, text}) => {
    return (
        <span className={"px-2 inline-flex text-xs leading-5 font-semibold rounded-full " + circleColors} >
        {text}
        </span>
    )
}

export default ColorfulCircle